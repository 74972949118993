<template>
  <aw-tabler :table="tableJSON">
    <template #filter>
      <el-form
        :model="tableJSON.filter"
        label-suffix="："
        inline
      >
        <el-form-item label="代理商名" prop="name">
          <el-input v-model="tableJSON.filter.name" placeholder="请输入代理商名"></el-input>
        </el-form-item>
      </el-form>
    </template>
    
    <template #drawercontent>
      <div class="add-forbid flex-column">
        <el-form
          ref="addSearchEle"
          :model="addSearch"
          label-suffix="："
          inline
          :disabled="addTableLoading"
        >
          <el-form-item label="代理商名" prop="name">
            <el-input v-model="addSearch.name" placeholder="请输入代理商名" />
          </el-form-item>
          <el-form-item label="手机号" prop="username">
            <el-input v-model="addSearch.username" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getAddTableData">查询</el-button>
            <el-button @click="addSearchEle.resetFields(); getAddTableData()">重置</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="addTableEle"
          class="table-data"
          v-loading="addTableLoading"
          :data="addTableData"
          row-key="id"
          border
        >
          <el-table-column
            type="selection"
            :selectable="(row) => row.is_disable === 0"
          />
          <template v-for="column in addTableJSON" :key="column.prop">
            <el-table-column
              :prop="column.prop"
              :label="column.label"
            />
          </template>
        </el-table>
        <div v-if="addTableTotal > addSearch.page_size" style="display: flex;flex-direction: row;justify-content: flex-end;margin-bottom: 20px">
          <el-pagination
            v-model:current-page="addSearch.page"
            v-model:page-size="addSearch.page_size"
            layout="prev, pager, next"
            :total="addTableTotal"
            background
            hide-on-single-page
            @current-change="getAddTableData"
          ></el-pagination>
        </div>
        <div class="flex-row btn-line">
          <el-button
            :loading="storeLoading"
            type="primary"
            @click="handlerStore"
          >保存</el-button>
        </div>
      </div>
    </template>
  </aw-tabler>
</template>

<script setup>
import {ref, reactive} from 'vue'
import { tableColumn } from '@/assets/js/extend/tableColumn'
import {http, tool} from "@/assets/js";
import {useRoute} from 'vue-router'
import {dayjs, ElMessage, ElMessageBox} from 'element-plus'

const route = useRoute()
// 操作权限
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.roles,'component'))

const tableJSON = ref({
  title: '用户禁加企微',
  loading: false,
  datas: [1],
  filter: {
    name: '',
  },
  columns: [
    ...tableColumn([
      {
        title: 'ID',
        key: 'agent_id',
        width: '100px'
      },
      {
        title: '代理商名',
        key: 'name',
        minWidth: '150px'
      },
      {
        title: '手机号',
        key: 'username',
        width: '130px'
      },
      {
        title: '创建人',
        key: 'truename'
      },
      {
        title: '创建时间',
        key: 'addtime',
        width: '180px',
        render: (row) => {
          return dayjs.unix(row.addtime).format('YYYY-MM-DD HH:mm:ss')
        }
      },
    ]),
    {
      fixed: 'right',
      title: '操作',
      key: 'action',
      width: '100px',
      buttons: [
        {
          name: '删除',
          props: { type: 'danger' },
          show: () => powerlimits.includes('yhjjqw_sc'),
          action: (row) => {
            
            ElMessageBox.confirm('确定删除该项吗？', '提示', {
              type: 'warning',
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(() => {
              http.post(`admin/Service/agentDisableDelete`, {
                ids: [row.agent_id]
              }).then((res) => {
                if(res && typeof res === 'number'){
                  ElMessage.success('删除成功')
                  tableJSON.value.action(tableJSON.value.filter)
                }
              })
            })
            
          }
        }
      ]
    }
  ],
  action: async (params) => {
    if(powerlimits.includes('yhjjqw_lb')) {
      tableJSON.value.loading = true
      let page = { page: tableJSON.value.page.page, page_size: tableJSON.value.page.page_size }
      let result = await http.post('/admin/Service/agentList', { ...params, ...route.query, ...page })
      tableJSON.value.datas = result.data
      tableJSON.value.page.total = result.total || Infinity
      tableJSON.value.page.page = result.current_page
      tableJSON.value.page.page_size = result.per_page
      tableJSON.value.loading = false
    } else {
      tableJSON.value.datas = []
      tableJSON.value.page.total = Infinity
    }
  },
  page: {
    total: 0,
    page: 1,
    page_size: 10,
    sizeChange: (val) => {
      tableJSON.value.page.page_size = val
      tableJSON.value.page.page = 1
      tableJSON.value.action(tableJSON.value.filter)
    },
    currentChange: (val) => {
      tableJSON.value.page.page = val
      tableJSON.value.action(tableJSON.value.filter)
    }
  },
  extras: [
    {
      name: '添加',
      props: { type: 'primary', plain: true },
      show: () => powerlimits.includes('yhjjqw_tj'),
      action: () => {
        tableJSON.value.drawer.show = true
        tableJSON.value.drawer.title = '添加'
        getAddTableData()
      }
    }
  ],
  drawer: {
    show: false,
    title: '',
    close: () => {
      addSearchEle.value.resetFields()
      tableJSON.value.drawer.show = false
    }
  }
})

const addSearchEle = ref()
const addSearch = ref({
  agentName: '',
  mobile: '',
  page: 1,
  page_size: 10,
})
const addTableEle = ref()
const addTableTotal = ref(0)
const addTableData = ref([])
const addTableJSON = ref([
  {
    label: 'ID',
    prop: 'id',
    width: 100
  },
  {
    label: '代理商名',
    prop: 'name'
  },
  {
    label: '合作类型',
    prop: 'with_type_name'
  },
  {
    label: '手机号',
    prop:'username'
  }
])
const addTableLoading = ref(false)
const getAddTableData = () => {
  if(!powerlimits.includes('yhjjqw_dlsxz')) return
  addTableLoading.value = true
  http.post(`/admin/Service/agentChooseList`, {
    ...addSearch.value
  }).then(res => {
    const {data, total} = res
    addTableData.value = data
    addTableTotal.value = total
  }).finally(() => {
    addTableLoading.value = false
  })
}
// 保存禁加企微
const storeLoading = ref(false)
const handlerStore = () => {
  if(!powerlimits.includes('yhjjqw_tj')) return;
  storeLoading.value = true
  const selected = addTableEle.value.getSelectionRows()
  if(selected.length === 0) {
    storeLoading.value = false
    ElMessage.warning('请勾选代理商')
    return
  }
  const ids = []
  selected.forEach(item => {
    ids.push(item.id)
  })
  http.post(`admin/Service/agentDisable`, {
    ids
  }).then((res) => {
    if(res && typeof res === 'number') {
      ElMessage.success('操作成功')
      addTableEle.value.clearSelection()
      tableJSON.value.drawer.close()
      tableJSON.value.action(tableJSON.value.filter)
    }
  }).finally(() => {
    storeLoading.value = false
  })
}
</script>

<style scoped lang="scss">
.flex-column{
  display: flex;
  flex-direction: column;
}
.flex-row{
  display: flex;
  flex-direction: row;
}
.add-forbid{
  .table-data{
    margin: 15px 0;
  }
  .btn-line{
    justify-content: flex-end;
  }
}
</style>
